$pozadina: rgb(197, 181, 165);
$slova:#765;
$linkovi:#b58585;
$meniBorder:rgb(107, 81, 52);
$meniBack:rgba(223, 207, 191, 1.0);

html {
    height: 100%;
    //box-sizing: border-box;
}

// *,
// *:before,
// *:after {
//   box-sizing: inherit;
// }

body {
    position: relative;
    background-color: $pozadina;
    margin: 0;
    padding: 0;
    padding-bottom: 2rem;
    min-height: 98%;
    font-family: 'Open Sans', sans-serif;
    overflow-x: hidden;
    color: $slova;
}

a {
    background: transparent;
    color: $linkovi;
}

a:hover {
    text-decoration: none;
    color: $slova;
}

footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 2rem;
    text-transform: uppercase;
    clear: both;
    text-align: center;
    border-top: 2px solid;
    font-size: 0.8rem;
}

nav,
.side {
    margin: 10px;
    background-color: $meniBack;
    border-radius: 5px;
    border-top: $meniBorder 5px solid;
    border-bottom: $meniBorder 5px solid;
    color: $slova;
    padding: 0;
}

.navbar-brand {
    padding: 0;
    margin-right: 10px;
}

.nav-item {
    margin: 0;
}

.nav-lang {
    color: rgb(47, 73, 47);
}

.active-lang {
    font-weight: bold;
    color: rgb(122, 37, 118);
}

.active-main {
    font-weight: bold;
    color: rgb(154, 142, 119) !important;
}

#main {
    display: block;
}

#langTag {
    display: none;
}

.slikaProfila {
    height: 10rem;
    border-radius: 10%;
    border-style: ridge;
    border-width: 0.3rem;
    border-color: rgb(154, 142, 119);
}

.sadrzaj {
    height: 100%;
    padding-bottom: 3rem;
}

.infoResponsive {
    display: none;
}

@media(max-width:991px) {
    .nav-link {
        font-size: 0.9rem;
    }

    .infoDesk {
        display: none;
    }

    .infoResponsive {
        display: block;
    }
}

.shirForme {
    width: 80%;
}

@media (max-width: 767px) {
    body {
        padding-bottom: 6rem;
    }

    h1 {
        font-size: 2rem;
    }

    footer {
        font-size: 0.6rem;
    }

    #logo {
        width: 120px;
    }

    .slikaProfila {
        height: 9rem;
    }

    .mislika {
        width: 90%;
    }

    .shirForme {
        width: 100%;
    }
}

@media(max-width: 350px) {
    .slikaProfila {
        height: 8rem;
    }
}

// @media (min-height: 768px) and (min-width: 1024px) {
//     footer {
//         position: absolute;
//         width: 90%;
//         bottom: 1.2rem;
//         left: 2%;
//     }
// }