html {
  height: 100%;
}

body {
  position: relative;
  background-color: #c5b5a5;
  margin: 0;
  padding: 0;
  padding-bottom: 2rem;
  min-height: 98%;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
  color: #765;
}

a {
  background: transparent;
  color: #b58585;
}

a:hover {
  text-decoration: none;
  color: #765;
}

footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 2rem;
  text-transform: uppercase;
  clear: both;
  text-align: center;
  border-top: 2px solid;
  font-size: 0.8rem;
}

nav,
.side {
  margin: 10px;
  background-color: #dfcfbf;
  border-radius: 5px;
  border-top: #6b5134 5px solid;
  border-bottom: #6b5134 5px solid;
  color: #765;
  padding: 0;
}

.navbar-brand {
  padding: 0;
  margin-right: 10px;
}

.nav-item {
  margin: 0;
}

.nav-lang {
  color: #2f492f;
}

.active-lang {
  font-weight: bold;
  color: #7a2576;
}

.active-main {
  font-weight: bold;
  color: #9a8e77 !important;
}

#main {
  display: block;
}

#langTag {
  display: none;
}

.slikaProfila {
  height: 10rem;
  border-radius: 10%;
  border-style: ridge;
  border-width: 0.3rem;
  border-color: #9a8e77;
}

.sadrzaj {
  height: 100%;
  padding-bottom: 3rem;
}

.infoResponsive {
  display: none;
}

@media (max-width: 991px) {
  .nav-link {
    font-size: 0.9rem;
  }

  .infoDesk {
    display: none;
  }

  .infoResponsive {
    display: block;
  }
}
.shirForme {
  width: 80%;
}

@media (max-width: 767px) {
  body {
    padding-bottom: 6rem;
  }

  h1 {
    font-size: 2rem;
  }

  footer {
    font-size: 0.6rem;
  }

  #logo {
    width: 120px;
  }

  .slikaProfila {
    height: 9rem;
  }

  .mislika {
    width: 90%;
  }

  .shirForme {
    width: 100%;
  }
}
@media (max-width: 350px) {
  .slikaProfila {
    height: 8rem;
  }
}


